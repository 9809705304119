import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    CardContent,
    CardMedia,
    CardActions ,
    IconButton,
    Grid,  
    Box,
  } from '@mui/material';

  import { showError, showSuccess } from '../../../utils/toast';
  import Page from '../../../components/Page';


  const PromotionPage = () => {

    return (
        <Page title='Promotions'>
            <Typography>Blank Page</Typography>
        </Page>
    )
  }

export default PromotionPage;