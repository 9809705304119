import * as React from 'react';
import {  Link as RouterLink, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import { Button, Stack, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Page from '../../../components/Page';
import { AlertDialog } from '../../../utils/diloag';

import getProperties, { deletePropertyById, getPublishedProperties, propertyStatusNames } from '../../../services/PropertyService';
import { showError, showSuccess } from '../../../utils/toast';
import { roleId } from '../../../utils/session';
import PropertiesDataAdmin from './data/PropertiesDataAdmin';
import StatusUpdateDialog from './components/StatusUpdateDialog';
import PublishPropertyDialog from './components/PublishPropertyDialog';
import { headerText } from './components/PropertyCard';

const PropertiesPage = () => {

  const loginRole = React.useRef(roleId())
  const [type, setType] = React.useState(propertyStatusNames.available);
  const [isLoading, setIsLoading] = React.useState(false)

  const [rejectedProperties, setRejectedProperties] = React.useState([]);
  const [inReviewProperties, setInReviewProperties] = React.useState([]);
  const [closedProperties, setClosedProperties] = React.useState([]);
  const [publishedProperties, setPublishedProperties] = React.useState([]);
  const [notAvailableProperties, setNotAvailableProperties] = React.useState([]);
  const [updateStatus, setUpdateStatus] = React.useState(null);
  const [publishProperty, setPublishProperty] = React.useState(null);
  const [loadData, setLoadData] = React.useState(false);
  const [deleteMessage, setDeleteMessage] = React.useState('');
  const deleteProperty = React.useRef(null)

  const navigate = useNavigate(); 

  React.useEffect(() => {
    console.log('type', type);
    if(!loadData && type===null) {
      return
    }
    setLoadData(false);
    getProperties(
      type,
      (properties) => {
        console.log('properties', properties);
        switch(type) {
          case propertyStatusNames.available:
            setPublishedProperties(properties)
            break
          case propertyStatusNames.inReview:
            setInReviewProperties(properties)
            break
          case propertyStatusNames.reject:
            setRejectedProperties(properties)
            break
          case propertyStatusNames.sold:
            setClosedProperties(properties)
            break
          case propertyStatusNames.notAvailable:
            setNotAvailableProperties(properties)
            break
        }
      },
      (error) => {
        showError(error);
      }
    )

  }, [type, loadData])

  const onSuccess = (msg) => {
    showSuccess(msg);
  }

  const onError = (msg) => {
    showError(msg);
  }

  const onEdit = (property) => {
    console.log('onEdit', property)
    navigate(`/dashboard/property/edit?property=${property.id}`)
  }

  const onReloadAvailableProperties = () => {

  }

  const onReloadData = () => {
    setLoadData(true);
  }
  

  const onUpdateStatus = (property) => {
    setUpdateStatus(property);
  }

  const onUpdatePublish = (property) => {
    setPublishProperty(property)
  }

  const onDelete = (property) => {
    setUpdateStatus(null);
    deleteProperty.current = property;
    setDeleteMessage(`Delete ${headerText(property)}`);
  }

  const onCancelDelete = () => {
    setDeleteMessage('');
  }

  const onDeleteClick = () => {
      setDeleteMessage('');
      if(deleteProperty.current) {
        deletePropertyById(deleteProperty.current?.id, 
          (response) => {
            showSuccess(response);
            setLoadData(true);
          },
          (error) => {
            showError(error)
          })
      }
  }

  if(loginRole.current == 'A') {
    return (
      <Typography>Page not found</Typography>
    )
  }

  const GetButtons = () => {

    switch(type) {
      case propertyStatusNames.available:
        return (
          <Stack direction='row' spacing={2}>
              <Button sx={{textTransform: 'none'}} variant='contained' size='small' >Published</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('R')}>In Review</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('X')}>Reject</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('S')}>Sold</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('N')}>Unpublished</Button>
          </Stack>
        )
      case propertyStatusNames.inReview:
        return (
          <Stack direction='row' spacing={2}>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('A')}>Published</Button>
              <Button sx={{textTransform: 'none'}} variant='contained' size='small'>In Review</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('X')}>Reject</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('S')}>Sold</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('N')}>Unpublished</Button>
          </Stack>
        )
      case propertyStatusNames.reject:
        return (
          <Stack direction='row' spacing={2}>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('A')}>Published</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('R')}>In Review</Button>
              <Button sx={{textTransform: 'none'}} variant='contained' size='small' >Reject</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('S')}>Sold</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('N')}>Unpublished</Button>
          </Stack>
        )
      case propertyStatusNames.sold:
        return (
          <Stack direction='row' spacing={2}>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('A')}>Published</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('R')}>In Review</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('X')}>Reject</Button>
              <Button sx={{textTransform: 'none'}} variant='contained' size='small' >Sold</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('N')}>Unpublished</Button>
          </Stack>
        )   
        case propertyStatusNames.notAvailable:
        return (
          <Stack direction='row' spacing={2}>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('A')}>Published</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('R')}>In Review</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('X')}>Reject</Button>
              <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('S')}>Sold</Button>
              <Button sx={{textTransform: 'none'}} variant='contained' size='small' >Unpublished</Button>
          </Stack>
        )
    }
  }


    return (
      <Page title='Properties'>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <GetButtons />
          {/* <Button sx={{textTransform: 'none', m:2}} variant="outlined" size='small' startIcon={<AddIcon />} onClick={() => navigate('/property/add')} >
            Add Property
          </Button> */}
        </Stack>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <PropertiesDataAdmin
            type={type}
            publishedProperties={publishedProperties}
            inReviewProperties={inReviewProperties}
            rejectedProperties={rejectedProperties}
            closedProperties={closedProperties}
            notAvailableProperties={notAvailableProperties}
            onUpdateStatus={onUpdateStatus}
            onUpdatePublish={onUpdatePublish}
            onEdit={onEdit} />
        </Grid>
        <AlertDialog showCancel={true} title={`Delete property!`} message={deleteMessage} onOkey={onDeleteClick} onCancel={onCancelDelete} />
        <StatusUpdateDialog property={updateStatus} setOpen={setUpdateStatus} onReloadData={onReloadData} onSuccess={onSuccess} onError={onError} onEdit={onEdit} onDelete={onDelete} />
      </Page>
    )

}

export default PropertiesPage;