import * as React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import MenuIcon from '@mui/icons-material/Menu';
import { headerText } from './PropertyCard';

import { dateToShowDateFormat, formatNumber } from '../../../../utils/ext';
import { propertyStatusNames } from '../../../../services/PropertyService';
import DefImage from '../../../../assets/town.png'
import { apiUrl } from '../../../../utils/api';


const GetEditButton = ({property, option, onEdit, onUpdateStatus, onUpdatePublish}) => {

  switch(option) {
    case propertyStatusNames.available:
      return (
        <IconButton
          aria-label="bookmark Bahamas Islands"
          variant="plain"
          color="neutral"
          size="sm"
          sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}
        >
          <Button variant='text' size='small' onClick={() => onUpdateStatus(property)}><MenuIcon color="primary" /></Button>
        </IconButton>   
      )
    case propertyStatusNames.notAvailable:
      return (
        <IconButton
          aria-label="bookmark Bahamas Islands"
          variant="plain"
          color="neutral"
          size="sm"
          sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}
        >
          <Button variant='text' size='small' onClick={() => onUpdatePublish(property)}><MenuIcon color="primary" /></Button>
        </IconButton>   
      )
    case propertyStatusNames.inReview:
    case propertyStatusNames.sold:      
      return null
      
    default:
      return (
          <IconButton
            aria-label="bookmark Bahamas Islands"
            variant="plain"
            color="neutral"
            size="sm"
            sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}
          >
            <Button variant='text' size='small' onClick={() => onEdit(property)}><EditIcon color="primary" /></Button>
          </IconButton>   
      )

  }
}

export const PhotoView = ({property}) => {
  if(property.photos.length > 0) {
      return (
        <img
          src={`${apiUrl.property}${property.photos[0].media}`}
          srcSet={`${property.photos[0]}`}
          loading="lazy"
          alt=""
          width={300}
          height={200}
      />
    )
  }
 return (
    <img
      src={DefImage}
      srcSet={DefImage}
      loading="lazy"
      alt=""
      width={300}
      height={200}
  />
)      
}

const MyPropertyCard = ({property, onEdit, onUpdateStatus, onUpdatePublish, option}) => {

  return (
    <Card variant="outlined" sx={{ width: 350, height: 350,  m:1, p:2 }}>
      <Typography level="h2" fontSize="md" sx={{ mb: 0.5 }}>
        {headerText(property)}
      </Typography>
      <Typography level="h6" fontSize="md" sx={{ mb: 0.5 }}>
        in {property.landmark}
      </Typography>      
      <IconButton
          aria-label="bookmark Bahamas Islands"
          variant="plain"
          color="neutral"
          size="sm"
          sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}
        >
          <Button variant='text' size='small' onClick={() => onUpdateStatus(property)}><MenuIcon color="primary" /></Button>
        </IconButton>   
      <Box  
        variant="plain"
        ratio="4/3"
        objectFit="contain"
        minHeight={120} 
        maxHeight={200}
        sx={{
          flexBasis: `200px`,
          borderRadius: 'sm',
          overflow: 'auto',
        }}
        >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={200}
      >
        <PhotoView property={property} />
      </Box>
      </Box>
      <Typography level="body2">{dateToShowDateFormat(property.created)}</Typography>
      <Box sx={{ display: 'flex' }}>
        <div>
          <Typography fontSize="lg" fontWeight="lg">
          {property.currency} {formatNumber(property.cost)} 
          </Typography>
        </div>
        <Button
          variant="contained"
          size="sm"
          color="primary"
          aria-label="Explore Bahamas Islands"
          sx={{ ml: 'auto', fontWeight: 600 }}
          component={Link}
          to={`/dashboard/property/details?property=${property?.id}`}
        >
          Explore
        </Button>
      </Box>
    </Card>
  );
}

export default MyPropertyCard