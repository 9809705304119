import { apiUrl, apiPostSecure, apiCallSecureGet, apiCallSecureDelete } from "../utils/api";


export default function getBookmarks(onResponse, onError) {

    apiCallSecureGet(
        apiUrl.bookmark,
        (response) => {
            onResponse(response.data)
        },
        (error) => {
            onError(error)
        } 
    )

}


export function saveBookmarks(id, onResponse, onError) {

    const data = {
        id: id
    }

    apiPostSecure(
        apiUrl.bookmark,
        data,
        (response) => {
            onResponse(response.msg)
        },
        (error) => {
            onError(error)
        } 
    )

}

export function deleteBookmarks(id, onResponse, onError) {

    apiCallSecureDelete(
        apiUrl.bookmark,
        id,
        (response) => {
            onResponse(response.msg)
        },
        (error) => {
            onError(error)
        } 
    )

}
