import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    CardContent,
    CardMedia,
    CardActions ,
    IconButton,
    Grid,  
    Box,
    Tooltip,
    ListItemIcon,
    Avatar,
  } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import LoadingModal from '../../../components/settings/LoadingModal';
import DialogModal from '../../../components/settings/DialogModal';

import Iconify from '../../../components/Iconify';
import Page from '../../../components/Page';

import NewAccountModal, { EditAccountModal } from './components/Modals';

import { apiCallSecureDelete, apiCallSecureGet, apiPostSecure, apiUrl } from '../../../utils/api';
import { stringAvatar } from '../../../utils/ext';
import { showError, showSuccess } from '../../../utils/toast';
import { roleId } from '../../../utils/session';


const columns = [
  {
    name: 'photo',
    label: 'Photo',
    headerStyle: {textAlign: 'center'},
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'contact',
    label: 'Contact',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'action',
    label: 'Actions',
    options: {
      filter: false,
      sort: false,
    },
  },
];


const agentColumns = [
  {
    name: 'photo',
    label: 'Photo',
    headerStyle: {textAlign: 'center'},
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'contact',
    label: 'Contact',
    options: {
      filter: false,
      sort: false,
    },
  }
];

const options = {
  filterType: 'dropdown',
  responsive: 'stacked',
  filter: false,
  download: false,
  print: false,
  selectableRows: false
};

const GetPhoto = ({account}) => {
  if(account.photo) {
    return (
        <Box 
        display="flex"
        justifyContent="center"
        alignItems="center">
          <Avatar alt={`${account.first_name} ${account.last_name}`} src={`${apiUrl.account}${account.photo}`} />
        </Box>
      )
  }
  return (
    <Box 
        display="flex"
        justifyContent="center"
        alignItems="center">
      <Avatar {...stringAvatar(`${account.first_name} ${account.last_name}`)} />
    </Box>
  )
}

const GetName = ({name, account_id, showDetails}) => {
  return (
    <Button variant='text' onClick={() => showDetails(account_id)}>
      {name}
    </Button>
  )
}
  
const ActionButtons = ({account, showEditAccount, showDeleteAccount}) => {
  return (
    <>
      <Tooltip title="Edit">
      <Button onClick={() => showEditAccount(account)} >
          <ListItemIcon style={{ justifyContent: 'center' }}>
            <Iconify icon="bxs:edit-location" width={24} height={24} />
          </ListItemIcon>
        </Button>
      </Tooltip>
      <Tooltip title="Delete" >
        <Button onClick={() => showDeleteAccount(account)}>
          <ListItemIcon style={{ justifyContent: 'center' }}>
            <Iconify icon="fluent:delete-20-filled" width={24} height={24} />
          </ListItemIcon>
        </Button>
      </Tooltip>      
    </>
  )
}

const DeactivateButton = ({account, showEditAccount, updateAccountStatusAccount, showDeleteAccount}) => {
  return (
    <>
      <Tooltip title="Deactivate">
        <Button onClick={() => updateAccountStatusAccount(account, 'D')}>
          <ListItemIcon style={{ justifyContent: 'center' }}>
            <Iconify icon="bxs:user-x" width={24} height={24} />
          </ListItemIcon>
        </Button>
      </Tooltip>
      <ActionButtons account = {account} showEditAccount={showEditAccount} showDeleteAccount={showDeleteAccount} />
    </>
  );
} 

const ActivateButton = ({account, showEditAccount, updateAccountStatusAccount, showDeleteAccount}) => {
  return (
    <>
      <Tooltip title="Activate">
        <Button onClick={() => updateAccountStatusAccount(account, 'A')}>
          <ListItemIcon style={{ justifyContent: 'center' }}>
            <Iconify icon="bxs:user-check" width={24} height={24} />
          </ListItemIcon>
        </Button>
      </Tooltip>
      <ActionButtons account = {account} showEditAccount={showEditAccount} showDeleteAccount={showDeleteAccount} />
    </>
  );
} 

const UsersPage = () => {

    const [loadUsers, setLoadUsers] = useState(true);
    const [showAddAccount, setShowAddAccount] = useState(false);
    const [users, setUsers] = useState([]);
    const [rows, setRows] = useState([]);
    const [editAccount, setEditAccount] = useState(null);
    const [deleteAccount, setDeleteAccount] = useState(null);
    const [loadingTitle, setLoadingTitle] = useState(null);
    const roleName = useRef(roleId())

    const navigate = useNavigate(); 

    useEffect(() => {

      if(!loadUsers) {
        return
      }
      setLoadUsers(false);
      const url = `${apiUrl.accountAdmin}?role=U`
      apiCallSecureGet(
        url,
        (response) => {
          setUsers(response.list);
          console.log('response', response);
        },
        (error) => {
          showError(error)
        }
      )
    }, [loadUsers])

  useEffect(() => {

    const data = []

    users.forEach((user) => {

      const actions = user.is_active ? <DeactivateButton account={user} showEditAccount={setEditAccount} showDeleteAccount={showDeleteAccount} updateAccountStatusAccount={updateAccountStatusAccount} /> : <ActivateButton account={user} showEditAccount={setEditAccount} showDeleteAccount={showDeleteAccount} updateAccountStatusAccount={updateAccountStatusAccount} />

      data.push(
        { 
          photo: <GetPhoto account={user} />,
          contact: user.email,
          name: <GetName name={`${user.first_name} ${user.last_name}`} account_id={user.accountId} showDetails={showDetails}/>, 
          action: actions
        }
      )
    })

    setRows(data)
  }, [users])

  const showDetails = (id) => {
    navigate(`/dashboard/users/details?account_id=${id}`)
  }

  const showEditAccount = (account) => {
    setEditAccount(account);
  }
  
  const showDeleteAccount = (account) => {
    setDeleteAccount(account);
  }

  const onNewAccount = (status, message) => {
      if(status === 1) {
        setLoadingTitle(null);
        setEditAccount(null);
        showSuccess(message);
        setLoadUsers(true);
      }else if(status === 2) {
        setLoadingTitle(null);
        showError(message)
      }else {
        setLoadingTitle(message);
      }
  }

  const deleteClickHandler = async (state) => {
    console.log('deleteClickHandler > ', state)
    apiCallSecureDelete(apiUrl.accountAdmin, state.accountId,
      (response) => {
        setDeleteAccount(null);
        showSuccess(response.msg)
        setLoadUsers(true);
        console.log('Account', response);
     },
     (errorMsg) => {
         setDeleteAccount(null);
         showError(errorMsg||'Error');
         console.log('Account', errorMsg||'Error');
     }
    )
  };

  const modalHandleClose = () => {
    setDeleteAccount(null);
  };

  const updateAccountStatusAccount = (account, status) => {

    const data = {
      'status': status,
      'id': account.accountId
    }
    apiPostSecure(apiUrl.accountStatus, data,
      (response) => {
        showSuccess(response.msg);
        setLoadUsers(true);
        console.log('account', response);
     },
     (errorMsg) => {
         showError(errorMsg||'Error');
         console.log('account', errorMsg||'Error');
     }
    )
  }

  const GetAddButton = () => {
    if(roleName.current == 'A') {
      return null
    }

    return (
      <Button
        sx={{ ml: 2 }}
        onClick={() => setShowAddAccount(true)}
        variant="contained"
        startIcon={<Iconify icon="eva:plus-fill" />}
      >
        Create Account
      </Button>
    )
  }

  const GetTable = () => {
    if(roleName.current == 'A') {
      return (
        <MUIDataTable title={'User List'} data={rows} columns={agentColumns} options={options} />
      )
    }

    return (
      <MUIDataTable title={'User List'} data={rows} columns={columns} options={options} />
    )
  }

  return (
        <Page title='Users'>
            <Container>
            <LoadingModal
              title={loadingTitle} />
            <EditAccountModal 
              state={editAccount} 
              change={setEditAccount} 
              onSaveChange={onNewAccount} 
              roleName={'U'}
              onError={showError} />
            <NewAccountModal
              show={showAddAccount} 
              change={setShowAddAccount} 
              onSaveChange={onNewAccount}
              roleName={'U'}
              onError={showError}
              />
            <DialogModal
              state={deleteAccount}
              title='Delete Account?'
              message={`Do you want to delete ${deleteAccount?.first_name} ${deleteAccount?.last_name} account?`}
              handleclose={modalHandleClose}
              addclickhandler={deleteClickHandler}
              buttonlabel="Yes, Delete"
            />
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                User Accounts
              </Typography>
              <Box>
                <Grid container justify="flex-end">
                  <GetAddButton />
                </Grid>
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} />
            <Card>
              <GetTable />
            </Card>
          </Container>
        </Page>
    )
  }

export default UsersPage;