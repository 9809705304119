import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import EditNote from '@mui/icons-material/EditNote';
import Avatar from '@mui/material/Avatar';
import { Grid, ListItemIcon, Stack, Tooltip } from '@mui/material';
import { red } from '@mui/material/colors';

import ErrorIcon from '@mui/icons-material/Error';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

import Iconify from '../../../../components/Iconify';

import { apiUrl } from '../../../../utils/api';

const GetEmailVerified = ({verified}) => {
  if(verified) {
    return (
      <Button variant="outlined" color='success' size="small">
        <ErrorIcon sx={{ color: red[500] }} /> Email verified
      </Button>
    )
  }
  return (
    <Button variant="outlined" color='warning' size="small" >
      <ErrorIcon sx={{ color: red[500] }} /> Email not verified
    </Button>
  )
}

const GetPhoneVerified = ({verified}) => {
    if(verified) {
      return (
        <Button variant="outlined" color='success' size="small" >
          <ErrorIcon sx={{ color: red[500] }} /> Phone verified
        </Button>
      )
    }
    return (
      <Button variant="outlined" color='warning' size="small" >
        <ErrorIcon sx={{ color: red[500] }} /> Phone not verified
      </Button>
    )
}
const AgentIcon = ({account}) => {
  if(account && account.role == 'A') {
    return (
      <Button variant="outlined" color='primary' size="small">
        <SupportAgentIcon  /> Agent Account
      </Button>
    )
  }

  return null;
}

const ActionButtons = ({account, showEditAccount, showDeleteAccount}) => {
  return (
    <>
      <Tooltip title="Edit">
      <Button variant='outlined' onClick={() => showEditAccount(account)} >
          <ListItemIcon style={{ justifyContent: 'center' }}>
            <Iconify icon="bxs:edit-location" width={24} height={24} />
          </ListItemIcon>
        </Button>
      </Tooltip>
      <Tooltip title="Delete" >
        <Button variant='outlined' onClick={() => showDeleteAccount(account)}>
          <ListItemIcon style={{ justifyContent: 'center' }}>
            <Iconify icon="fluent:delete-20-filled" width={24} height={24} />
          </ListItemIcon>
        </Button>
      </Tooltip>      
    </>
  )
}

const DeactivateButton = ({account, showEditAccount, updateAccountStatusAccount, showDeleteAccount}) => {
  return (
    <>
      <Tooltip title="Deactivate">
        <Button variant='outlined' onClick={() => updateAccountStatusAccount(account, 'D')}>
          <ListItemIcon style={{ justifyContent: 'center' }}>
            <Iconify icon="bxs:user-x" width={24} height={24} />
          </ListItemIcon>
        </Button>
      </Tooltip>
      <ActionButtons account = {account} showEditAccount={showEditAccount} showDeleteAccount={showDeleteAccount} />
    </>
  );
} 

const ActivateButton = ({account, showEditAccount, updateAccountStatusAccount, showDeleteAccount}) => {
  return (
    <>
      <Tooltip title="Activate">
        <Button variant='outlined' onClick={() => updateAccountStatusAccount(account, 'A')}>
          <ListItemIcon style={{ justifyContent: 'center' }}>
            <Iconify icon="bxs:user-check" width={24} height={24} />
          </ListItemIcon>
        </Button>
      </Tooltip>
      <ActionButtons account = {account} showEditAccount={showEditAccount} showDeleteAccount={showDeleteAccount} />
    </>
  );
} 

const AccountInfoCard = ({account, role, showEditAccount, updateAccountStatusAccount, showDeleteAccount}) => {

  if(account == null) {
    return null
  }
  const getActions = () => {
    if(role == 'X') {
      const actions = account.is_active ? <DeactivateButton account={account} showEditAccount={showEditAccount} showDeleteAccount={showDeleteAccount} updateAccountStatusAccount={updateAccountStatusAccount} /> : <ActivateButton account={account} showEditAccount={showEditAccount} showDeleteAccount={showDeleteAccount} updateAccountStatusAccount={updateAccountStatusAccount} />
      return actions
    }

    return null
  }


  return (
    <Card  sx={{ m:1, p:2}}>
       <Typography level="h1" fontSize="md">
          Account Details
        </Typography>

      <Grid container spacing={2} justify="space-between" alignItems="stretch">
        <Grid item sm={12} lg={2}>
          <Avatar
                alt={`${account?.first_name} ${account?.last_name}`}
                src={`${apiUrl.account}${account.photo}`} 
                sx={{ width: 140, height: 140, m: 2 }}
          />  
        </Grid>
        <Grid item sm={12} lg={8}>
          <Box>
            <Typography level="body1" fontSize="sm">
              Name
            </Typography>
            <Typography level="h2" fontSize="md" sx={{ mb: 2}}>
              {account?.first_name} {account?.last_name}
            </Typography>  
          </Box> 
          <Box>
            <Typography level="body1" fontSize="sm">
              Email
            </Typography>
            <Typography level="h2" fontSize="md" sx={{ mb: 2 }}>
              {account?.email}
            </Typography>  
          </Box>   
          <Box>
            <Typography level="body1" fontSize="sm">
              Phone Number
            </Typography>
            <Typography level="h2" fontSize="md" sx={{ mb: 2 }}>
              {account?.mobile}
            </Typography>  
          </Box>   
          <Stack direction='row' spacing={2}>
            <GetPhoneVerified verified={account?.phone_verified} />
            <GetEmailVerified verified={account?.email_verified} />
            <AgentIcon account={account} />
          </Stack>
        </Grid>
        <Grid item sm={12} lg={2}>
          <Stack direction='row-reverse' spacing={2}>
              {getActions}
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
}

export default AccountInfoCard;