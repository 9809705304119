
import * as React from 'react';
import Grid from '@mui/material/Box';
import PropertyCard from '../../properties/components/PropertyCard';

  const GetContent = ({porperties, onSuccess, onError}) => {
    const data = [];
    porperties.forEach((item) => 
        data.push(
            <Grid item xs={12} md={6} lg={4}>
                <PropertyCard property={item} onSuccess={onSuccess} onError={onError} />                    
            </Grid>
        )
    )
    return data
}


const PropertiesDataAgent = ({ 
  properties, onSuccess, onError
}) => {

  return (
    <GetContent porperties={properties} onSuccess={onSuccess} onError={onError}/>
  ) 

}

export default PropertiesDataAgent;