import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    CardContent,
    CardMedia,
    CardActions ,
    IconButton,
    Grid,  
    Box,
  } from '@mui/material';

  import { showError, showSuccess } from '../../../utils/toast';
  import Page from '../../../components/Page';
import ChatView from './components/ChatView';


  const ChatPage = () => {

    return (
        <Page title='Chat'>
            {/* <Typography>Chats</Typography> */}
            <ChatView />
        </Page>
    )
  }

export default ChatPage;