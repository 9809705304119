import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    CardContent,
    CardMedia,
    CardActions ,
    IconButton,
    Grid,  
    Box,
    LinearProgress,
  } from '@mui/material';

  import { showError, showSuccess } from '../../../utils/toast';
  import Page from '../../../components/Page';
import {getRecentProperties} from '../../../services/PropertyService';
import PropertiesData from '../search/data/PropertiesDataAgent';


  const RecentPage = () => {

    const [properties, setProperties] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false)


    React.useEffect(() => {
      setIsLoading(true);
      getRecentProperties(
        (list) => {
          console.log('list', list);
          setProperties(list);
          setIsLoading(false);
        },
        (error) => {
          showError(error)
        }
      )
    }, [])

    const GetProgress = () => {
      if (isLoading) {
        return <LinearProgress />
      }
      return null
    }    

    return (
        <Page title='Recent'>
            <Typography variant='h6'>Recent Properties</Typography>
            <Box sx={{ width: '100%', p: 1 }}>
              <GetProgress />
            </Box>   
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <PropertiesData properties={properties} onSuccess={showSuccess} onError={showError} />
            </Grid>                          
        </Page>
    )
  }

export default RecentPage;