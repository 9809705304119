
import * as React from 'react';
import Grid from '@mui/material/Box';
import MyPropertyCard from '../../properties/components/MyPropertyCard';

  const GetContent = ({option, properties, onEdit, onUpdateStatus, onUpdatePublish}) => {
    const data = [];
    console.log('properties', properties);
    properties?.forEach((item) => 
        data.push(
            <Grid item xs={12} md={6} lg={4}>
                <MyPropertyCard property={item} onUpdateStatus={onUpdateStatus} onUpdatePublish={onUpdatePublish} onEdit={onEdit} option={option}/>                    
            </Grid>
        )
    )
    return data
}


const PropertiesDataAdmin = ({ 
  properties,
  onEdit, 
  onUpdateStatus, 
  onUpdatePublish 
}) => {

  return (
    <GetContent properties={properties} onEdit={onEdit} onUpdateStatus={onUpdateStatus} onUpdatePublish={onUpdatePublish}/>
  )

}

export default PropertiesDataAdmin;