import { Grid, Box } from "@mui/material";
import { Button } from "@mui/material";

export const commercialOptions = ['Office', 'Retail', 'Plot/Land', 'Storage', 'Indusrty', 'Hospitality', 'other']
export const residentalOptions = ['Flat/Apartment', 'Independant house/Villa', 'Independant  builder/floor', '1RK Studio Apartment', 'Farmhouse', 'other']


const CommercialData = ({selected, setSelected}) => {
    const data = [];
    commercialOptions.map((item) => {
        if(selected === item) {
            data.push(
                <Button  key={item} sx={{m:1}} variant="contained" >{item}</Button>
            )
        }else {
            data.push(
                <Button  key={item} sx={{m:1}} variant="outlined" onClick={() => setSelected(item)}>{item}</Button>
            )
        }
    })
    return data;

}

export const GetCommercialProperties = ({selected, setSelected}) => { 
    return(
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)',  }}>
            <CommercialData selected={selected} setSelected={setSelected} />
        </Box>
    )
}


const ResidentalData = ({selected, setSelected}) => {
    const data = [];
    residentalOptions.map((item) => {
        if(selected === item) {
            data.push(
                <Button key={item} sx={{m:1}}  variant="contained">{item}</Button>
            )
        }else {
            data.push(
                <Button  key={item} sx={{m:1}}  variant="outlined" onClick={() => setSelected(item)}>{item}</Button>
            )
        }
    })
    return data;

}

const GetResidentalProperties = ({selected, setSelected}) => { 
    return(
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
            <ResidentalData selected={selected} setSelected={setSelected} />
        </Box>
    )
}

export default GetResidentalProperties;