import { useRef, useEffect } from "react";
import { TextField, Box, Autocomplete } from "@mui/material";
import { useState } from "react";
import {searchPlaceCountry} from '../../../../utils/api'
import { removeHtmlTags } from "../../../../utils/ext";

export const autoPalceOptions = {
    componentRestrictions: { country: "sg" },
    fields: ['address_components', 'geometry', 'icon', 'name', 'adr_address', 'geometry.location', 'place_id', 'url'],
    types: ["establishment"]
};

export const getAddressFromPlace = (place) => {

    let name = place.name;
    let area = '';
    let city = '';
    let country = '';
    let address = place.adr_address;
    let place_id = place.place_id;
    let url = place.url;

    place.address_components?.map((item) => {
            if(item.types.includes('route')) {
                area = item.long_name;
            }else if(item.types.includes('locality')) {
                city = item.long_name;
            }else if(item.types.includes('country')) {
                country = item.long_name;
            }
    })


    return {
        name: name?.toLowerCase(), 
        area: area?.toLowerCase(), 
        city: city?.toLowerCase(), 
        country: country?.toLowerCase(), 
        address: removeHtmlTags(address)?.toLowerCase(), 
        place_id: place_id, 
        url: url
    }

}

const PlaceAutoComplete = ({selectedPlace, setSelectedPlace}) => {
    const autoCompleteRef = useRef();
    const inputRef = useRef();
    const mapRef = useRef();
    const mapStateRef = useRef();
    const markerRef = useRef();
    const [query, setQuery] = useState('');

    useEffect(() => {
        const LatLng = {lat: 1.295933, lng: -103.786796};
        const mapOptions = {
            center: LatLng,
            zoom: 12,
            scrollwheel:false,
            noClear: true
        }

        mapStateRef.current = new window.google.maps.Map(mapRef.current, mapOptions);

        markerRef.current = new window.google.maps.Marker({
            position: LatLng,
            map: mapStateRef.current,
            draggable:true,
            animation: window.google.maps.Animation.DROP,
        });

        autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, autoPalceOptions);
        autoCompleteRef.current.bindTo('bounds', mapStateRef.current);

        const map = mapStateRef.current
        if(map == null) {
            return
        }

        window.google.maps.event.addListener(autoCompleteRef.current, 'place_changed',function(){
            const place= autoCompleteRef.current.getPlace();
            if (!place.geometry){
                return;
            }
            if (place.geometry.viewport) {
                map.fitBounds(place.geometry.viewport);
            } else {
                map.setCenter(place.geometry.location);
                map.setZoom(17);
            }

            markerRef.current?.setPlace( ({
                placeId: place.place_id,
                location: place.geometry.location
            }));
            // console.log('place', place);
            // console.log('place', getAddressFromPlace(place));
            setSelectedPlace(place);
        });

        if(selectedPlace) {
            const place = selectedPlace;
            if (!place.geometry){
                return;
            }
            if (place.geometry.viewport) {
                map.fitBounds(place.geometry.viewport);
            } else {
                map.setCenter(place.geometry.location);
                map.setZoom(17);
            }

            markerRef.current?.setPlace( ({
                placeId: place.place_id,
                location: place.geometry.location
            }));

            console.log('place', place);
        }

    }, [])


 return (
  <Box sx={{width: '45vw', height: '45vh'}}>
    <input type="text" id="accommodation_address" ref={inputRef} style={{width: '100%', padding: '8px', marginBottom: '8px'}} value={query} onChange={(e) =>  setQuery(e.target.value)}/>
    <div ref={mapRef} id='map' style={{width: '100%', height: '100%'}}>
    </div>
  </Box>
 );
};




export default PlaceAutoComplete;