import * as React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import MenuIcon from '@mui/icons-material/Menu';
import BookmarkAdd from '@mui/icons-material/BookmarkAddOutlined';
import BookmarkIcon from '@mui/icons-material/Bookmark';

import { dateToShowDateFormat, formatNumber } from '../../../../utils/ext';
import { propertyStatusNames } from '../../../../services/PropertyService';
import DefImage from '../../../../assets/town.png'
import { apiUrl } from '../../../../utils/api';
import { deleteBookmarks, saveBookmarks } from '../../../../services/BookmarkService';
import { PhotoView } from './MyPropertyCard';

export const headerText = (property) => {
  return `${property?.type_name} for ${property?.sale_type}`
}

const GetIcon = ({bookmark, onAddBookmark, onRemoveBookmark}) => {
  if(bookmark) {
      return <Button variant='text' size='small' onClick={onRemoveBookmark}><BookmarkIcon color="primary"  /></Button>
  }
  return <Button variant='text' size='small' onClick={onAddBookmark}><BookmarkAdd /></Button>
}

const PropertyCard = ({property, onSuccess, onError}) => {


  const [bookmark, setBookmark] = React.useState(property.bookmark)

  const onAddBookmark = () => {

    setBookmark(true);
    console.log('onAddBookmark', bookmark);
    saveBookmarks(property.id, (response) => {
      onSuccess(response);
    }, (error) => {
      setBookmark(false);
      onError(error)
    })
  }

  const onRemoveBookmark = () => {
    setBookmark(false);
    console.log('onRemoveBookmark', bookmark);
    deleteBookmarks(property.id, (response) => {
      onSuccess(response);
    }, (error) => {
      setBookmark(true);
      onError(error)
    })
  }



  return (
    <Card variant="outlined" sx={{ width: 350, height: 350,  m:1, p:2 }}>
      <Typography level="h2" fontSize="md" sx={{ mb: 0.5 }}>
        {headerText(property)}
      </Typography>
      <Typography level="h6" fontSize="md" sx={{ mb: 0.5 }}>
        in {property.landmark}
      </Typography>      
      <IconButton
          aria-label="bookmark Bahamas Islands"
          variant="plain"
          color="neutral"
          size="sm"
          sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}
        >
          <GetIcon bookmark={bookmark} onAddBookmark={onAddBookmark} onRemoveBookmark={onRemoveBookmark}/>
        </IconButton>   
      <Box  
        variant="plain"
        ratio="4/3"
        objectFit="contain"
        minHeight={120} 
        maxHeight={200}
        sx={{
          flexBasis: `200px`,
          borderRadius: 'sm',
          overflow: 'auto',
        }}
        >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={200}
      >
        <PhotoView property={property} />
      </Box>
      </Box>
      <Typography level="body2">{dateToShowDateFormat(property.created)}</Typography>
      <Box sx={{ display: 'flex' }}>
        <div>
          <Typography fontSize="lg" fontWeight="lg">
          {property.currency} {formatNumber(property.cost)} 
          </Typography>
        </div>
        <Button
          variant="contained"
          size="sm"
          color="primary"
          aria-label="Explore Bahamas Islands"
          sx={{ ml: 'auto', fontWeight: 600 }}
          component={Link}
          to={`/dashboard/property/details?property=${property?.id}`}
        >
          Explore
        </Button>
      </Box>
    </Card>
  );
}

export default PropertyCard