import * as React from 'react';
import { Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import HideSourceIcon from '@mui/icons-material/HideSource';
import ReceiptIcon from '@mui/icons-material/Receipt';
import GppGoodIcon from '@mui/icons-material/GppGood';
import CancelIcon from '@mui/icons-material/Cancel';
import { Delete } from '@mui/icons-material';

import { headerText } from './PropertyCard';
import { propertyStatusNames, updatePropertyStatus } from '../../../../services/PropertyService';

export const GetStatusButtons = ({property, onEdit, onDelete, onUpdateStatus}) => {
  
      const buttons = []
      buttons.push(
        <Button variant='outlined' size='small' sx={{m:1}} startIcon={<EditIcon />} onClick={() => onEdit(property)}>Edit</Button>
      )
      console.log('status', property);
      switch(property?.status) {
        case propertyStatusNames.available:
          buttons.push(
            <Button variant='outlined' size='small' sx={{m:1}} startIcon={<HideSourceIcon />} onClick={() => onUpdateStatus(propertyStatusNames.notAvailable)}>Un Publish</Button>
          )
          buttons.push(
            <Button variant='outlined' size='small' sx={{m:1}} startIcon={<ReceiptIcon />} onClick={() => onUpdateStatus(propertyStatusNames.sold)}>Sold</Button>
          )
          break
        case propertyStatusNames.inReview:
          buttons.push(
            <Button variant='outlined' size='small' sx={{m:1}} startIcon={<GppGoodIcon />} onClick={() => onUpdateStatus(propertyStatusNames.available)}>Publish</Button>
          )
          buttons.push(
            <Button variant='outlined' size='small' sx={{m:1}} startIcon={<CancelIcon />} onClick={() => onUpdateStatus(propertyStatusNames.reject)}>Reject</Button>
          )
          break
        case propertyStatusNames.reject:
          buttons.push(
            <Button variant='outlined' size='small' sx={{m:1}} startIcon={<GppGoodIcon />} onClick={() => onUpdateStatus(propertyStatusNames.available)}>Publish</Button>
          )
          break
        case propertyStatusNames.sold:
          buttons.push(
            <Button variant='outlined' size='small' sx={{m:1}} startIcon={<GppGoodIcon />} onClick={() => onUpdateStatus(propertyStatusNames.available)}>Publish Again</Button>
          )
          break
        case propertyStatusNames.notAvailable:
          buttons.push(
            <Button variant='outlined' size='small' sx={{m:1}} startIcon={<GppGoodIcon />} onClick={() => onUpdateStatus(propertyStatusNames.available)}>Publish</Button>
          )
          break
      }

      buttons.push(
        <Button variant='outlined' size='small' sx={{m:1}} color='error' startIcon={<Delete />} onClick={() => onDelete(property)}>Delete</Button>
      )

      return buttons;
}

export default function StatusUpdateDialog({ property, setOpen, onEdit, onDelete, onReloadData, onSuccess, onError}) {

    const onUpdateStatus = (status) => {
        updatePropertyStatus(
          property?.id,
          status,
          (response) => {
              onSuccess(response)
              onReloadData();
              handleClose();
          },
          (error) => {
              onError(error)
          }
        )
      };
    
    const handleClose = () => {
        setOpen(null);
    };

  return (
      <Dialog
        open={property != null}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            {headerText(property)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
                <Stack
                    direction='column' 
                    sx={{mb:1}}>
                        <Typography variant='subtitle'>
                            {property?.address}
                        </Typography>
                        <Typography variant='subtitle'>
                            Update property status.
                        </Typography>
                    </Stack>
              <Stack
                direction='row' 
                spacing={2} 
                sx={{mx:4, mt:4}}
                justifyContent="center"
                alignItems="center">

                <GetStatusButtons property={property} onEdit={onEdit} onDelete={onDelete} onUpdateStatus={onUpdateStatus} />

              </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
  );
}