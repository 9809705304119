import React, { useState, useEffect } from 'react';
import {  Link as RouterLink, useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";

// material
import { Link, Stack, Box, TextField, IconButton, InputAdornment, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
// import { showToast } from '../../../utils/toast';
import { logout, saveUser } from "../../../../utils/session";
import { apiUrl, apiPostUnsecure } from "../../../../utils/api";
import { showError, showSuccess } from '../../../../utils/toast';

// ----------------------------------------------------------------------

export default function LoginForm() {
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);

    const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
    } = useForm();
    const [error, setError] = useState("");

    function onLoginTest(data) {
      console.log("onSubmit");
      console.log("data", data);
    }

    async function login(data) {
      setLoading(true);
      apiPostUnsecure(
        apiUrl.signIn,
        data,
        (response) => {
          setLoading(false);
          saveUser(response);
          showSuccess('Welcome to SBS Transit');
          navigate("/dashboard/app");
        },
        (errorMsg) => {
          setLoading(false);
          console.log('error', errorMsg)
          showError(errorMsg);
        }
      );
    }

    // if(isAuthenticated()) {
    //   return <Redirect to='/dashboard'  />
    // }
    // console.log("errors", errors);
    // console.log("username", watch("username"));
    // console.log("password", watch("password"));


  const [clearStorage, setClearStorage] = useState(true);

  useEffect(()=> {
    setClearStorage(false);
    logout();
  },[clearStorage])


  const [showPassword, setShowPassword] = useState(false);

  return (
    <Box >
      <form id="hook-form" onSubmit={handleSubmit(login)}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            label="Email address"
            {...register("username", { required: "Enter username" })}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...register("password", { required: "Enter passowrd" })}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Link component={RouterLink} variant="subtitle2" to="/forgot-password" underline="hover">
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton fullWidth size="large" type='submit'  form="hook-form" variant="contained" loading={isLoading} >
          Login
        </LoadingButton>
      </form>
    </Box>
  );
}
