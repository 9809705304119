import React, { useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

import DashboardPage from './pages/main/dashoard';
import PropertiesPage from './pages/main/properties';
import ChatPage from './pages/main/chat';
import AgentsPage from './pages/main/agents';
import UsersPage from './pages/main/users';
import PromotionPage from './pages/main/promotions';
import AccountPage from './pages/main/account';
import SettingsPage from './pages/main/settings';
import PaymentsPage from './pages/main/payments';
import DetailsPage from './pages/main/details';
import BlankLayout from './layouts/BlankLayout';
import PropertyEditpage from './pages/main/property-edit';
import AccountDetails from './pages/main/userProfile'
import SearchPage from './pages/main/search';
import BookmarksPage from './pages/main/bookmarks';
import RecentPage from './pages/main/rcents';

//
import Login from './pages/auth/Login';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import Activate from './pages/auth/Activate';
import NotFound from './pages/Page404';
import Register from './pages/auth/Register';

export default function Router() {

  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardPage /> },
        { path: 'property', 
          children: [
            { path: '', element: <PropertiesPage /> },
            { path: 'search', element: <SearchPage /> },
            { path: 'details', element: <DetailsPage /> },
            { path: 'edit', element: <PropertyEditpage /> },
          ]
        },
        { path: 'search', element: <SearchPage /> },
        { path: 'bookmarks', element: <BookmarksPage /> },
        { path: 'agents', element: <AgentsPage /> },
        { path: 'recent', element: <RecentPage /> },
        { path: 'users', 
          children:[
            {path: '', element: <UsersPage />},
            {path: 'details', element: <AccountDetails />}
          ] 
        },
        { path: 'promotions', element: <PromotionPage /> },
        { path: 'chat', element: <ChatPage /> },
        { path: 'payments', element: <PaymentsPage /> },
        { path: 'account', element: <AccountPage /> },
        { path: 'settings', element: <SettingsPage /> },
        { path: 'details', element: <DetailsPage /> },
        { path: 'property-edit', element: <PropertyEditpage /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'activate', element: <Activate /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    // { path: '*', element: <Navigate to="/404" replace /> },
  ]);
  
}
