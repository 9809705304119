import { apiUrl, apiPostSecure, apiCallSecureGet, apiCallSecureDelete } from "../utils/api";


export const propertyStatusNames = {
    available: 'A',
    notAvailable: 'N',
    sold: 'S',
    drafts: 'D',
    reject: 'X',
    inReview: 'R',
}

export const propertyTypeNames = {
    any: 'A',
    residental: 'R',
    commercial: 'C',
}

export const getStatusName = (status) => {
    switch(status) {
        case 'A': return 'Available';
        case 'N': return 'Not Available';
        case 'S': return 'Sold';
        case 'D': return 'Draft';
        case 'X': return 'Reject';
        case 'R': return 'In Review';
    }

    return status
}

export async function getRecentProperties(onResponse, onError) {

    apiCallSecureGet(
        apiUrl.recentProperty,
        (response) => {
            onResponse(response.data)
        },
        (error) => {
            onError(error)
        } 
    )

}

export default async function getProperties(type, onResponse, onError) {

    apiCallSecureGet(
        `${apiUrl.propertyList}?status=${type}`,
        (response) => {
            onResponse(response.list)
        },
        (error) => {
            onError(error)
        } 
    )

}

export async function getPropertiesForAccount(type, account_id, onResponse, onError) {

    apiCallSecureGet(
        `${apiUrl.propertyList}?status=${type}&account_id=${account_id}`,
        (response) => {
            onResponse(response.list)
        },
        (error) => {
            onError(error)
        } 
    )

}

export async function getPublishedProperties(onResponse, onError) {
    await getProperties('A', onResponse, onError)
}

export async function getDraftProperties(onResponse, onError) {
    await getProperties('D', onResponse, onError)
}

export async function getInReviewProperties(onResponse, onError) {
    await getProperties('R', onResponse, onError)
}

export async function getClosedProperties(onResponse, onError) {
    await getProperties('S', onResponse, onError)
}

export async function getNotAvailableProperties(onResponse, onError) {
    await getProperties('N', onResponse, onError)
}

export async function deletePropertyById(id, onResponse, onError) {
    await apiCallSecureDelete(
        apiUrl.propertyList,
        id,
        (response) => {
            onResponse(response.msg)
        },
        (error) => {
            onError(error)
        } 
    )
}

export async function getHomeProperties(onResponse, onError) {
    apiCallSecureGet(
        apiUrl.homeProperties,
        (response) => {
            onResponse(response.list)
        },
        (error) => {
            onError(error)
        } 
    )
}


export async function updatePropertyStatus(id, status, onResponse, onError) {

    const body = {
        id: id,
        status: status
    }

    apiPostSecure(
        apiUrl.propertyStatus,
        body,
        (response) => {
            onResponse(response.msg)
        },
        (error) => {
            onError(error)
        } 
    )
}

export async function searchProperty(query, onResponse, onError) {

    apiPostSecure(
        apiUrl.searchProperties,
        query,
        (response) => {
            onResponse(response.list)
        },
        (error) => {
            onError(error)
        } 
    )
}

export async function searchPropertyForAgent(query, onResponse, onError) {

    apiPostSecure(
        apiUrl.searchPropertiesForAgent,
        query,
        (response) => {
            onResponse(response.list)
        },
        (error) => {
            onError(error)
        } 
    )
}

export async function getPropertyDetails(id, onResponse, onError) {
    const url = `${apiUrl.propertyInfo}?property=${id}`
    apiCallSecureGet(
        url,
        (response) => {
            console.log('response', response);
            onResponse(response.data);
        },
        (error) => {
            onError(error);
        } 
    )
}

export async function getPropertyDetailsForAgent(id, onResponse, onError) {
    const url = `${apiUrl.propertyInfoAgent}?property=${id}`
    apiCallSecureGet(
        url,
        (response) => {
            console.log('response', response);
            onResponse(response.data);
        },
        (error) => {
            onError(error);
        } 
    )
}