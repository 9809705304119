
import * as React from 'react';
import {  Link as RouterLink, useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { Card, Stack } from '@mui/material';
import { EditAccountModal } from '../users/components/Modals';
import LoadingModal from '../../../components/settings/LoadingModal';
import DialogModal from '../../../components/settings/DialogModal';
import AccountInfoCard from './components/AccountInfoCard';
import Iconify from '../../../components/Iconify';
import { AlertDialog } from '../../../utils/diloag';

import { apiUrl, apiCallSecureGet, apiCallSecureDelete, apiPostSecure } from '../../../utils/api';
import { showSuccess, showError } from '../../../utils/toast';
import { useQueryParams } from '../../../utils/ext';
import StatusUpdateDialog from '../properties/components/StatusUpdateDialog';
import PropertiesData from '../properties/data/PropertiesDataAdmin';
import getProperties, { deletePropertyById, getPropertiesForAccount, propertyStatusNames } from '../../../services/PropertyService';
import ChatView from './components/ChatView';
import { headerText } from '../properties/components/PropertyCard';
import { roleId } from '../../../utils/session';
import PropertiesDataAgent from '../search/data/PropertiesDataAgent';


const AccountDetails = () => {

    const [openUpdate, setOpenUpdate] = React.useState(false);
    const [openChangePassword, setOpenChangePassword] = React.useState(false);
    const [openVerifyPhone, setOpenVerifyPhone] = React.useState(false);
    const [openVerifyEmail, setOpenVerifyEmail] = React.useState(false);

    const [loadAccount, setLoadAccount] = React.useState(true);
    const [account, setAccount] = React.useState(null);
    const [editAccount, setEditAccount] = React.useState(null);
    const [deleteAccount, setDeleteAccount] = React.useState(null);
    const [loadingTitle, setLoadingTitle] = React.useState(null);
    const [tab, setTab] = React.useState(0)

  // properties
    
  const [type, setType] = React.useState(propertyStatusNames.available);
  const [isLoading, setIsLoading] = React.useState(false)

  const [rejectedProperties, setRejectedProperties] = React.useState([]);
  const [inReviewProperties, setInReviewProperties] = React.useState([]);
  const [closedProperties, setClosedProperties] = React.useState([]);
  const [publishedProperties, setPublishedProperties] = React.useState([]);
  const [notAvailableProperties, setNotAvailableProperties] = React.useState([]);
  const [draftProperties, setDraftProperties] = React.useState([]);
  const [updateStatus, setUpdateStatus] = React.useState(null);
  const [publishProperty, setPublishProperty] = React.useState(null);
  const [loadData, setLoadData] = React.useState(false);
  const [deleteMessage, setDeleteMessage] = React.useState('');
  const deleteProperty = React.useRef(null)

  const roleName = React.useRef(roleId())

  const navigate = useNavigate(); 
  const { account_id } = useQueryParams()

  React.useEffect(() => {
    console.log('type', type, 'loadData', loadData);
    if(!loadData && type===null) {
      return
    }
    setLoadData(false);
    loadProperties();

  }, [type, loadData])


  const loadProperties = () => {
    getPropertiesForAccount(
      type,
      account_id,
      (properties) => {
        console.log('properties', properties);
        switch(type) {
          case propertyStatusNames.available:
            setPublishedProperties(properties)
            break
          case propertyStatusNames.inReview:
            setInReviewProperties(properties)
            break
          case propertyStatusNames.reject:
            setRejectedProperties(properties)
            break
          case propertyStatusNames.sold:
            setClosedProperties(properties)
            break
          case propertyStatusNames.notAvailable:
            setNotAvailableProperties(properties)
            break
          case propertyStatusNames.drafts:
            setDraftProperties(properties)
            break            
        }
      },
      (error) => {
        showError(error);
      }
    )
  }

  React.useEffect(() => {

    if(!loadAccount || !account_id) {
        return
    }
    setLoadAccount(false);
    const api = `${apiUrl.accountAdmin}?id=${account_id}`
    apiCallSecureGet(
        api,
        (response) => {
            console.log('response', response);
            setAccount(response.data);
        },
        (error) => {
            showError(error);
          }
      )

  }, [loadAccount])


  const onSuccess = (msg) => {
    showSuccess(msg);
  }

  const onError = (msg) => {
    showError(msg);
  }

  const onEdit = (property) => {
    console.log('onEdit', property)
    navigate(`/dashboard/property/edit?property=${property.id}`)
  }

  const onReloadAvailableProperties = () => {

  }

  
  const onDelete = (property) => {
    setUpdateStatus(null);
    deleteProperty.current = property;
    setDeleteMessage(`Delete ${headerText(property)}`);
  }

  const onCancelDelete = () => {
    setDeleteMessage('');
  }

  const onDeleteClick = () => {
      setDeleteMessage('');
      if(deleteProperty.current) {
        deletePropertyById(deleteProperty.current?.id, 
          (response) => {
            showSuccess(response);
            onReloadData();
          },
          (error) => {
            showError(error)
          })
      }
  }

  const onReloadData = () => {
    console.log('onReloadData', loadData)
    loadProperties();
  }
  

  const onUpdateStatus = (property) => {
    setUpdateStatus(property);
  }

  const onUpdatePublish = (property) => {
      setPublishProperty(property);
      console.log('onUpdatePublish', property);
  }

    const showDetails = (id) => {
        navigate(`/dashboard/users/details?account_id=${id}`)
    }

    const showDeleteAccount = (account) => {
        setDeleteAccount(account);
      }
    
    const onNewAccount = (status, message) => {
          if(status === 1) {
            setLoadingTitle(null);
            setEditAccount(null);
            showSuccess(message);
            setLoadAccount(true);
          }else if(status === 2) {
            setLoadingTitle(null);
            showError(message)
          }else {
            setLoadingTitle(message);
          }
      }
    
    const deleteClickHandler = async (state) => {
        console.log('deleteClickHandler > ', state)
        apiCallSecureDelete(apiUrl.accountAdmin, state.accountId,
          (response) => {
            setDeleteAccount(null);
            showSuccess(response.msg)
            navigate(`/dashboard/users`, {replace: true})
            console.log('Account', response);
         },
         (errorMsg) => {
             setDeleteAccount(null);
             showError(errorMsg||'Error');
             console.log('Account', errorMsg||'Error');
         }
        )
      };
    
    const modalHandleClose = () => {
        setDeleteAccount(null);
      };
    
    const updateAccountStatusAccount = (account, status) => {
    
        const data = {
          'status': status,
          'id': account.accountId
        }
        apiPostSecure(apiUrl.accountStatus, data,
          (response) => {
            showSuccess(response.msg);
            setLoadAccount(true);
            console.log('account', response);
         },
         (errorMsg) => {
             showError(errorMsg||'Error');
             console.log('account', errorMsg||'Error');
         }
        )
      }

    const GetPropertyButtons = () => {
      
      if(roleName.current == 'A') {
        return null;
      }
      switch(type) {
        case propertyStatusNames.available:
          return (
            <Stack direction='row' spacing={2}>
                <Button sx={{textTransform: 'none'}} variant='contained' size='small' >Published</Button>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('R')}>In Review</Button>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('X')}>Reject</Button>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('S')}>Sold</Button>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('N')}>Unpublished</Button>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('D')}>Drafts</Button>
            </Stack>
          )
        case propertyStatusNames.inReview:
          return (
            <Stack direction='row' spacing={2}>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('A')}>Published</Button>
                <Button sx={{textTransform: 'none'}} variant='contained' size='small'>In Review</Button>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('X')}>Reject</Button>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('S')}>Sold</Button>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('N')}>Unpublished</Button>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('D')}>Drafts</Button>
            </Stack>
          )
        case propertyStatusNames.reject:
          return (
            <Stack direction='row' spacing={2}>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('A')}>Published</Button>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('R')}>In Review</Button>
                <Button sx={{textTransform: 'none'}} variant='contained' size='small' >Reject</Button>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('S')}>Sold</Button>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('N')}>Unpublished</Button>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('D')}>Drafts</Button>
            </Stack>
          )
        case propertyStatusNames.sold:
          return (
            <Stack direction='row' spacing={2}>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('A')}>Published</Button>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('R')}>In Review</Button>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('X')}>Reject</Button>
                <Button sx={{textTransform: 'none'}} variant='contained' size='small' >Sold</Button>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('N')}>Unpublished</Button>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('D')}>Drafts</Button>
            </Stack>
          )   
          case propertyStatusNames.notAvailable:
          return (
            <Stack direction='row' spacing={2}>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('A')}>Published</Button>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('R')}>In Review</Button>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('X')}>Reject</Button>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('S')}>Sold</Button>
                <Button sx={{textTransform: 'none'}} variant='contained' size='small' >Unpublished</Button>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('D')}>Drafts</Button>
            </Stack>
          )
          case propertyStatusNames.drafts:
          return (
            <Stack direction='row' spacing={2}>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('A')}>Published</Button>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('R')}>In Review</Button>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('X')}>Reject</Button>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('S')}>Sold</Button>
                <Button sx={{textTransform: 'none'}} variant='outlined' size='small' onClick={() => setType('N')}>Unpublished</Button>
                <Button sx={{textTransform: 'none'}} variant='contained' size='small' >Drafts</Button>
            </Stack>
          )          
      }
    }

    const GetButtons = () => {
      if(tab == 0) {
        return (
          <Grid container justify="flex-end">
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              onClick={() => setTab(0)}
              startIcon={<Iconify icon="ri:home-office-line" />}
            >
              Properties
            </Button>
            <Button
              sx={{ ml: 2 }}
              onClick={() => setTab(1)}
              variant="outlined"
              startIcon={<Iconify icon="fluent:chat-32-regular" />}
            >
              Chat
            </Button>
          </Grid>
        )
      }

      return (
        <Grid container justify="flex-end">
          <Button
            sx={{ ml: 2 }}
            variant="outlined"
            onClick={() => setTab(0)}
            startIcon={<Iconify icon="ri:home-office-line" />}
          >
            Properties
          </Button>
          <Button
            sx={{ ml: 2 }}
            onClick={() => setTab(1)}
            variant="contained"
            startIcon={<Iconify icon="fluent:chat-32-regular" />}
          >
            Chat
          </Button>
        </Grid>
      )
    }

    const TabName = ({tab}) => {
      if(tab == 0) {
        return (
          <Typography variant='h6'>Properties</Typography>
        )
      }
      return (
        <Typography variant='h6'>Chats</Typography>
      )
    }


    const GetContent = ({tab}) => {
      if(tab == 0) {

        if(roleName.current == 'A') {
          return (
            <Box  sx={{ m:1, p:2}}>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <PropertiesDataAgent properties={publishedProperties} onSuccess={showSuccess} onError={showError} />
              </Grid> 
            </Box>
          )          
        }

        return (
          <Box  sx={{ m:1, p:2}}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <GetPropertyButtons />
              </Stack>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <PropertiesData
                  type={type}
                  publishedProperties={publishedProperties}
                  inReviewProperties={inReviewProperties}
                  rejectedProperties={rejectedProperties}
                  closedProperties={closedProperties}
                  notAvailableProperties={notAvailableProperties}
                  draftProperties={draftProperties}
                  onUpdateStatus={onUpdateStatus}
                  onUpdatePublish={onUpdatePublish}
                  onEdit={onEdit} />
              </Grid>
          </Box>
        )
      }
      return (
        <ChatView account={account} />
        // <Typography>Chats</Typography>
      )
      
    }

    return (
        <Box>
            <AccountInfoCard account={account} role={roleName} showEditAccount={setEditAccount} showDeleteAccount={showDeleteAccount} updateAccountStatusAccount={updateAccountStatusAccount} />
            <Card  sx={{ m:1, p:2}}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                  <TabName tab={tab}/>
                </Typography>
                <Box>
                  <GetButtons />
                </Box>
              </Stack>
            </Card>
            <GetContent tab={tab} />
            <DialogModal
              state={deleteAccount}
              title='Delete Account?'
              message={`Do you want to delete ${deleteAccount?.first_name} ${deleteAccount?.last_name} account?`}
              handleclose={modalHandleClose}
              addclickhandler={deleteClickHandler}
              buttonlabel="Yes, Delete"
            />
            <EditAccountModal 
              state={editAccount} 
              change={setEditAccount} 
              onSaveChange={onNewAccount} 
              roleName={'U'}
              onError={showError} />            
            <LoadingModal
              title={loadingTitle} />
            <AlertDialog showCancel={true} title={`Delete property!`} message={deleteMessage} onOkey={onDeleteClick} onCancel={onCancelDelete} />
            <StatusUpdateDialog property={updateStatus} setOpen={setUpdateStatus} onReloadData={onReloadData} onSuccess={onSuccess} onError={onError} onEdit={onEdit} onDelete={onDelete} />

        </Box>

    )

}

export default AccountDetails;