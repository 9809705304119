import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    CardContent,
    CardMedia,
    CardActions ,
    IconButton,
    Grid,  
    Box,
    alpha, 
    TextField, 
    Checkbox, 
    FormControlLabel, 
    Paper,
    InputBase,
    Menu,
    MenuItem,
    Tabs,
    Tab,
    Modal,
    Fade,
    LinearProgress
  } from '@mui/material';

  import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
  import SearchIcon from '@mui/icons-material/Search';

  import Page from '../../../components/Page';

  import { showError, showSuccess } from '../../../utils/toast';
  import { deletePropertyById, propertyTypeNames, searchProperty, searchPropertyForAgent } from '../../../services/PropertyService';
  import { commercialOptions, residentalOptions } from '../properties/components/PropertyTypes';
  import SearchBar from './components/SearchBar';
import PropertyCard, { headerText } from '../properties/components/PropertyCard';
import PropertiesDataAgent from './data/PropertiesDataAgent';
import { roleId } from '../../../utils/session';
import { AlertDialog } from '../../../utils/diloag';
import StatusUpdateDialog from '../properties/components/StatusUpdateDialog';
import PropertiesDataAdmin from './data/PropertiesDataAdmin';

  export const GetPropertyContent = ({properties, onSuccess, onError}) => {
    const data = [];
    properties.forEach((item) => 
        data.push(
            <Grid item xs={3}>
                <PropertyCard property={item} onSuccess={onSuccess} onError={onError} />                    
            </Grid>
        )
    )
    return data
}

const SearchPage = () => {

    const [successMessage, setSuccessMessage] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false)
    const [properties, setProperties] = React.useState([]);
    const [loadData, setLoadData] = React.useState(true);

    const [updateStatus, setUpdateStatus] = React.useState(null);
    const [deleteMessage, setDeleteMessage] = React.useState('');
    const deleteProperty = React.useRef(null)

    const isSearching = React.useRef(false);
    const roleName = React.useRef(roleId())
    const lastQuery = React.useRef(null)

    const navigate = useNavigate(); 

    const onSearch = (query) => {
      console.log('onSearch', isSearching.current);
      if(isSearching.current) {
        return;
      }
      lastQuery.current = query;
      setIsLoading(true);
      isSearching.current = true;
      if(roleName.current == 'A') {
        searchPropertyForAgent(
          query,
          (response) => {
            setIsLoading(false);
            console.log('response', response);
            setProperties(response);
            setTimeout(() => {isSearching.current = false}, 3000);
          },
          (error) => {
            setIsLoading(false);
            showError(error);
            setTimeout(() => {isSearching.current = false}, 3000);
          }  
        )
      }else {
        searchProperty(
          query,
          (response) => {
            setIsLoading(false);
            console.log('response', response);
            setProperties(response);
            setTimeout(() => {isSearching.current = false}, 3000);
          },
          (error) => {
            setIsLoading(false);
            showError(error);
            setTimeout(() => {isSearching.current = false}, 3000);
          }  
        )
      }
    }

    const GetProgress = () => {
      if(isLoading) {
        return <LinearProgress />
      }
      return null
    }

    const onEdit = (property) => {
      console.log('onEdit', property)
      navigate(`/dashboard/property/edit?property=${property.id}`)
    }
    
    const onReloadData = () => {
      onReSearch();
    }
    
    const onReSearch = () => {
      if(lastQuery.current) {
        onSearch(lastQuery.current);
      }
    }
  
    const onUpdateStatus = (property) => {
      setUpdateStatus(property);
    }
  
    const onUpdatePublish = (property) => {
      // setPublishProperty(property)
      onReSearch()
    }
  
    const onDelete = (property) => {
      setUpdateStatus(null);
      deleteProperty.current = property;
      setDeleteMessage(`Delete ${headerText(property)}`);
    }
  
    const onCancelDelete = () => {
      setDeleteMessage('');
    }
  
    const onDeleteClick = () => {
        setDeleteMessage('');
        if(deleteProperty.current) {
          deletePropertyById(deleteProperty.current?.id, 
            (response) => {
              showSuccess(response);
              setLoadData(true);
            },
            (error) => {
              showError(error)
            })
        }
    }

    const GetContents = () => {

        if(roleName.current == 'A') {
          return (
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <PropertiesDataAgent properties={properties} onSuccess={showSuccess} onError={showError} />
            </Grid>    
          )
        }

        return (
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <PropertiesDataAdmin properties={properties} onEdit={onEdit} onUpdatePublish={onUpdatePublish} onUpdateStatus={onUpdateStatus} />
          </Grid>    
        )
    }

    return (
        <Page title='Search'>
            <SearchBar onSearch={onSearch}/>
            <Box sx={{ width: '100%', p:1 }}>
              <GetProgress />
            </Box>  
            <Box sx={{ width: '100%', p:1 }}>
              <Typography variant='h6' sx={{ marginTop: '20px'}}>Search Results</Typography>
            </Box>   
            <GetContents />
            <AlertDialog showCancel={true} title={`Delete property!`} message={deleteMessage} onOkey={onDeleteClick} onCancel={onCancelDelete} />
            <StatusUpdateDialog property={updateStatus} setOpen={setUpdateStatus} onReloadData={onReloadData} onSuccess={showSuccess} onError={showError} onEdit={onEdit} onDelete={onDelete} />
        </Page>
    )
  }

export default SearchPage;