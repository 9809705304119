import React, { useEffect, useState } from 'react'

import MapPicker from 'react-google-map-picker'
import Geocode from "react-geocode";
import { Box, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { GoogleMapsAPI } from './client-config';

const DefaultLocation = { lat: 1.3521, lng: 103.8198};
const DefaultZoom = 12;

Geocode.setApiKey( GoogleMapsAPI );
Geocode.enableDebug();


const getAddressFromLatLon = (location, onResult, onError) => {
  Geocode.fromLatLng( location.lat , location.lng ).then(
    response => {
      const address = response.results[0].formatted_address;
      const addressArray =  response.results[0].address_components;
      const city = '';
      const area = '';
      const state = '';
            

      console.log( 'city', city, area, state );

      onResult({
        address: ( address ) ? address : '',
        area: ( area ) ? area : '',
        city: ( city ) ? city : '',
        state: ( state ) ? state : '',
      }) 
    },
    error => {
      console.error( error );
      onError(error);
    }
  );
}

const LocationPickerModal = ({open, setOpen, onAddressSelect}) => {

  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  const [selectedAddress, setSelectedAddress] = useState('')
  const [error, setError] = useState('')

  function handleChangeLocation (lat, lng){
    setLocation({lat:lat, lng:lng});
  }
  
  function handleChangeZoom (newZoom){
    setZoom(newZoom);
  }

  function handleResetLocation(){
    setDefaultLocation({...DefaultLocation});
    setLocation({...DefaultLocation});
    setZoom(DefaultZoom);
  }

  useEffect(() => {
    getAddressFromLatLon(location, (address) => {
      console.log('address > ', address);
      setSelectedAddress(address?.address)
    },
    (error) => {
      console.log('address > ', error);
      setError(error)
    });
    
  }, [location])

  const onLocationSelect = () => {
      onAddressSelect(selectedAddress, location);
      setOpen(false);
  }

  return (
    <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Box
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
          }}
        >
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
            Select property location from map.
          </Typography>
          <Stack direction='column' spacing={2} >
              <Typography variant='h5'>Select property address from map.</Typography>
              <Box sx={{width: '50vh'}}>
                  <MapPicker id="map"  
                    defaultLocation={defaultLocation}
                    zoom={zoom}
                    mapTypeId="roadmap"
                    onChangeLocation={handleChangeLocation} 
                    onChangeZoom={handleChangeZoom}
                    apiKey={GoogleMapsAPI}
                    sx={{height: '200px'}}
                    />
              </Box>
              <Stack alignItems='center'>
                <Typography variant='h3' sx={{p:2}} color="primary" >{selectedAddress}</Typography>
              </Stack>
              <Typography variant='h6' color='error'>{error}</Typography>
              <Button variant='contained' onClick={onLocationSelect}>Select this Address</Button>
          </Stack>
        </Box>
      </Modal>
  );
}

export default LocationPickerModal
