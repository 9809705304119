// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

 const getMenuOptions = (role) => {

  console.log('getMenuOptions', role);
  let options = []
  if(role === 'X') {
    options = [
      {
        title: 'Dashboard',
        path: '/dashboard/app',
        icon: getIcon('eva:pie-chart-2-fill'),
      },
      {
        title: 'Properties',
        path: '/dashboard/property',
        icon: getIcon('mingcute:building-5-fill'),
      },
      {
        title: 'Search',
        path: '/dashboard/search',
        icon: getIcon('mdi:map-search'),
      },
      {
        title: 'Agents',
        path: '/dashboard/agents',
        icon: getIcon('ic:sharp-real-estate-agent'),
      },
      {
        title: 'Users',
        path: '/dashboard/users',
        icon: getIcon('mdi:users-group'),
      },
      {
        title: 'Promotions',
        path: '/dashboard/promotions',
        icon: getIcon('ep:menu'),
      },
      {
        title: 'Chat',
        path: '/dashboard/chat',
        icon: getIcon('fluent:chat-12-filled'),
      },
      {
        title: 'Payments',
        path: '/dashboard/payments',
        icon: getIcon('fa6-solid:money-bill-trend-up'),
      }
    ];
  }else if(role === 'A') {
    options = [
      {
        title: 'Dashboard',
        path: '/dashboard/app',
        icon: getIcon('eva:pie-chart-2-fill'),
      },
      {
        title: 'Properties',
        path: '/dashboard/property/search',
        icon: getIcon('mingcute:building-5-fill'),
      },
      {
        title: 'Bookmarks',
        path: '/dashboard/bookmarks',
        icon: getIcon('ic:baseline-bookmarks'),
      },      
      {
        title: 'Chat',
        path: '/dashboard/chat',
        icon: getIcon('fluent:chat-12-filled'),
      },
      {
        title: 'Users',
        path: '/dashboard/users',
        icon: getIcon('mdi:users-group'),
      },
      {
        title: 'Recently Viewed',
        path: '/dashboard/recent',
        icon: getIcon('mdi:recent'),
      }
    ];
  }

  console.log('options', options);
  return options;

}


export const getSettings = (role) => {
  if(role === 'A') {
    return [
      {
        title: 'Account',
        path: '/dashboard/account',
        icon: getIcon('material-symbols:admin-panel-settings-rounded'),
      }
    ];
  }

  return [
    {
      title: 'Account',
      path: '/dashboard/account',
      icon: getIcon('material-symbols:admin-panel-settings-rounded'),
    }
  ];
}


export default getMenuOptions
/*
  {
    title: 'Departments',
    path: '/dashboard/departments',
    icon: getIcon('fa6-solid:map-location-dot'),
  }
  {
    title: 'Hosts',
    path: '/dashboard/hosts',
    icon: getIcon('eva:person-done-fill'),
  },
  {
    title: 'Robots',
    path: '/dashboard/robots',
    icon: getIcon('ant-design:robot-filled'),
  },
  */