import * as React from 'react';
import { useForm } from "react-hook-form";

import {
    Box,
    Grid,
    Button,
    Container,
    CssBaseline,
    TextField,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
  } from '@mui/material';
  
import validator from 'validator'

import { ErrorMessage } from '@hookform/error-message';
import { apiCallPostFormData, apiCallPostWithForm, apiCallPutWithForm, apiUrl } from '../../../../utils/api';

import ImagePreview, {ImagePreviewReset} from '../../../../components/imagePreview/ImagePreview';

const defY = 1;
const createPassword = () => {
    return Math.random().toString(36).slice(2, 7);
}

export const EditAccountModal = (props) => {

    const { state, roleName, change, onSaveChange, onError } = props;

    const [photo, setPhoto] = React.useState(null);
    const [updateApiCalling, setUpdateApiCalling] = React.useState(false);

    const handleClose = () => change(null);
  
    const {
        register,
        handleSubmit,
        setValue,
        setError,
        clearErrors,
        reset,
        formState: { errors },
      } = useForm();

      const updateMemberData = (data) => {

        setTimeout(() => {
            clearErrors();
        }, 3000);

        console.log('data', data);

        if(data.email) {
            if (!validator.isEmail(data.email)) {
                setError('email', {
                    type: "server",
                    message: 'Invalid Email',
                  });
                console.log('email', errors.first_name);
                return
            }
        }

        if(updateApiCalling) {
            return
        }

        state.first_name = data.first_name;
        state.last_name = data.last_name;
        state.email = data.email;
        state.mobile = data.mobile;

        setUpdateApiCalling(true);
        const formData = new FormData();
        formData.append('photo', photo);
        formData.append('id', data.accountId);
        formData.append('first_name', data.first_name);
        formData.append('last_name', data.last_name);
        formData.append('email', data.email);
        formData.append('mobile', data.mobile);
        formData.append('role', roleName);
        formData.append('password', data.new_password);

        console.log('password', data.new_password);

        onSaveChange(0, "Saving account...");
        apiCallPostFormData(apiUrl.accountAdmin, formData,
            (response) => {
                setUpdateApiCalling(false);
                reset({
                    accountId: "",
                    first_name: "",
                    last_name: "",
                    email: "",
                    mobile: "",
                    password: "",
                  }
                );
                onSaveChange(1, response.msg);
           },
           (errorMsg) => {
                setUpdateApiCalling(false);
                onSaveChange(2,  errorMsg||'Error');
                console.log('account', errorMsg||'Error');
           }
          )


        console.log('valid', data);
    } 

    const onImageSet = (file) => {
        console.log('file', file.name);
        setPhoto(file)
    }

    if(state != null) {
        setValue('accountId', state.accountId);
        setValue('first_name', state.first_name);
        setValue('last_name', state.last_name);
        setValue('email', state.email);
        setValue('mobile', state.mobile);
        setValue('password', '');
    }

    return (
        <Dialog open={state != null} onClose={handleClose}>
        <DialogTitle>Update Account</DialogTitle>
        <DialogContent>
            <form id="update-form" onSubmit={handleSubmit(updateMemberData)}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >
                    <ImagePreview
                            logo={`${apiUrl.account}${state?.photo}`}
                            onFileSelectSuccess={(file) => onImageSet(file)}
                            onFileSelectError={({ error }) => onError(error)}
                            />
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="First Name *"
                                    autoFocus
                                    {...register("first_name", { required: "Enter first name" })}
                                    />
                                    {errors.first_name && <p>{errors.first_name.message}</p>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Last Name *"
                                    {...register("last_name", { required: "Enter last name" })}
                                    />
                                    {errors.last_name && <p>{errors.last_name.message}</p>}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Mobile number"
                                    {...register("mobile")}
                                    />
                            </Grid>                            
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    inputProps={{
                                       autoComplete: 'new-password',
                                     }}
                                    label="Email Address"
                                    type="email"
                                    {...register("email",  { required: "Enter email!" })}
                                    />
                                    {errors.email && <p>{errors.email.message}</p>}
                            </Grid>        
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    inputProps={{
                                       autoComplete: 'new-password',
                                     }}
                                    label="Password (Optional)"
                                    {...register("new_password")}
                                    />
                            </Grid>                                                 
                            <Grid item xs={12}>
                                <ErrorMessage
                                    errors={errors}
                                    name="singleErrorInput"
                                    render={({ message }) => <p>{message}</p>}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    </Box>
                </Container>
            </form>  
        </DialogContent>
        <DialogActions>
            <Button variant="outlined" onClick={() => handleClose()}>Close</Button>
            <Button variant="contained" type='submit'  form="update-form">Save</Button>
        </DialogActions>
    </Dialog>
    )
}

const NewAccountModal = (props) => {

    const { show, change, roleName, onSaveChange, onError } = props;

    const [photo, setPhoto] = React.useState(null);
    const [preview, setPreview] = React.useState();
    const [addApiCalling, setAddApiCalling] = React.useState(false);

    const handleClose = () => {
        reset({
            first_name: "",
            last_name: "",
            email: "",
            mobile: "",
          }
        );
        change(false);
    }

    console.log('NewAccountModal', show)

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        reset,
        formState: { errors },
      } = useForm();

    const saveData = (data) => {

        setTimeout(() => {
            clearErrors();
        }, 3000);

        console.log('data', data.email);

        if (!validator.isEmail(data.email)) {
            setError('email', {
                type: "server",
                message: 'Invalid Email',
              });
            console.log('email', errors.first_name);
            return
        }
        

        if(addApiCalling) {
            return
        }
        setAddApiCalling(true)

        const formData = new FormData();
        formData.append('photo', photo);
        formData.append('first_name', data.first_name);
        formData.append('last_name', data.last_name);
        formData.append('email', data.email);
        formData.append('mobile', data.mobile);
        formData.append('password', data.password);
        formData.append('role', roleName);
        

        console.log(' >>> add Account return >> ')

        onSaveChange(0, "Saving Account...");
        apiCallPostWithForm(apiUrl.accountAdmin, formData,
            (response) => {
                setAddApiCalling(false)
                reset({
                    first_name: "",
                    last_name: "",
                    email: "",
                    mobile: "",
                    password: "",
                  }
                );
                setPhoto(null);
                setPreview(null);
                onSaveChange(1, response.msg);
           },
           (errorMsg) => {
                setAddApiCalling(false)
                onSaveChange(2,  errorMsg||'Error');
                console.log('account', errorMsg||'Error');
           }
          )

        // reset({});

        console.log('valid', data);
    } 

    const onImageSet = (file) => {
        setPhoto(file);
        console.log('file', file.name);
    }


    return (
        <Dialog open={show} onClose={handleClose}>
        <DialogTitle>Register Account</DialogTitle>
        <DialogContent>
            <form id="save-form" onSubmit={handleSubmit(saveData)}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >
                    <ImagePreviewReset
                        preview={preview} setPreview={setPreview}
                        setPhoto={setPhoto}
                        onFileSelectSuccess={(file) => onImageSet(file)}
                        onFileSelectError={({ error }) => onError(error)}
                    />
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="First Name *"
                                    autoFocus
                                    {...register("first_name", { required: "Enter first name" })}
                                    />
                                    {errors.first_name && <p>{errors.first_name.message}</p>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Last Name *"
                                    {...register("last_name", { required: "Enter last name" })}
                                    />
                                    {errors.last_name && <p>{errors.last_name.message}</p>}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Mobile number"
                                    {...register("mobile")}
                                    />
                            </Grid>                            
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    inputProps={{
                                       autoComplete: 'new-password',
                                     }}
                                    label="Email Address"
                                    type="email"
                                    {...register("email",  { required: "Enter email!" })}
                                    />
                                    {errors.email && <p>{errors.email.message}</p>}
                            </Grid>        
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    inputProps={{
                                       autoComplete: 'new-password',
                                     }}
                                    label="Password"
                                    type="password"
                                    {...register("password", { required: "Enter password!" })}
                                    />
                                    {errors.password && <p>{errors.password.message}</p>}
                            </Grid>                              
                            <Grid item xs={12}>
                                <ErrorMessage
                                    errors={errors}
                                    name="singleErrorInput"
                                    render={({ message }) => <p>{message}</p>}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    </Box>
                </Container>
            </form>  
        </DialogContent>
        <DialogActions>
            <Button variant="outlined" onClick={() => handleClose()}>Close</Button>
            <Button variant="contained" type='submit'  form="save-form">Save</Button>
        </DialogActions>
    </Dialog>
    )
  }

  export default NewAccountModal;